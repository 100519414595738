import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Globals } from "@foh/globals";
@Injectable({
  providedIn: "root"
})
export class VenuesService {
  constructor(private http: HttpClient, private globals: Globals) {}

  getList(params) {
    return this.http.get(this.globals.getUrl("URL_VENUES_LIST"), {
      params: params
    });
  }
  getDetails(params) {
    return this.http.get(this.globals.getUrl("URL_VENUES_DETAILS"), {
      params: params
    });
  }

  update(params) {
    return this.http.post(this.globals.getUrl("URL_VENUES_UPDATE"), params);
  }

  getCitadelList(){
    return this.http.get(this.globals.getUrl("URL_CITADEL_VENUES_LIST"));
  }
}
