import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Globals } from "@foh/globals";

@Injectable({
  providedIn: 'root'
})
export class TicketcodesService {

  constructor(private http: HttpClient, private globals: Globals) { }

  validate(params){
    return this.http.get(this.globals.getUrl("URL_EVENTTICKETCODES_VALIDATE"), {
      params: params
    });
  }

  getList(params) {
    return this.http.get(this.globals.getUrl("URL_EVENTTICKETCODES_LIST"), {
      params: params
    });
  }

  getDetail(params){
    return this.http.get(this.globals.getUrl("URL_EVENTTICKETCODES_DETAILS"), {
      params: params
    });
  }

  add(params) {
    return this.http.post(
      this.globals.getUrl("URL_EVENTTICKETCODES_ADD"),
      params
    );
  }
  update(params) {
    return this.http.post(
      this.globals.getUrl("URL_EVENTTICKETCODES_UPDATE"),
      params
    );
  }
  delete(id) {
    return this.http.delete(this.globals.getUrl("URL_EVENTTICKETCODES_DELETE") + `/${id}`)}
}
