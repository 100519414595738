import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { VenuesService } from "@foh/shared/services/common/venues.service";

@Component({
  selector: "app-venues-list",
  templateUrl: "./venues-list.component.html",
  styleUrls: ["./venues-list.component.scss"]
})
export class VenuesListComponent implements OnInit {
  rows: any = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  totalElements: number;
  pageNumber: number;
  size: number;
  redirectUrl : string;
  constructor(private venuesService: VenuesService) {}

  ngOnInit(): void {
    this.pageNumber = 0;
    this.size = 25;
    this.refresh();
    this.setRedirectUrl();
  }
  setRedirectUrl(){
    this.redirectUrl  = "/ordering/venues/manage-venue/";
  }

  refresh() {
    var params = {
      pagenumber: this.pageNumber
    };
    this.venuesService.getList(params).subscribe(response => {
      this.totalElements = response["total"];
      this.rows = response["list"];
    });
  }

  setPage(pageInfo) {
    this.pageNumber = pageInfo.offset;
    this.refresh();
  }
}
